import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import UnderMaintenance from "./Pages/maintainece";
import Home from "./Pages/Home";
import Destinations from "./Pages/Destinations";
import Events from "./Pages/Events";
import Daypass from "./Pages/Daypass";
import Blogs from "./Pages/Blogs";
import Contact from "./Pages/Contact";
import Bookings from "./components/Bookings";
import Order_detail from "./components/Order_detail";
import Product_detail from "./components/Product_detail";
import Blog_detail from "./components/BolgContent/Blog_detail";
import Properties from "./components/Properties";
import MyBooking from "./components/myBooking";
import Cancel from "./components/CancelScreens/Cancel";
import EventRooms from "./components/Event_Content/EventRooms";
import EventDetail from "./components/Event_Content/EventDetail";

import PropertiesDetail from "./components/PropertiesDetail";
import ProceedCancel from "./components/CancelScreens/ProceedCancel";
import RefundScreen from "./components/CancelScreens/RefundScreen";
import Error from "./Pages/Error";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Bounce } from "react-toastify";
import Login from "./Pages/Login";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import MyProfile from "./Pages/MyProfile";
import About from "./Pages/About";
import Preloader from "./components/Preloader";
import Authenticate from "./Pages/Authenticate";
import CancelPolicy from "./components/CancelPolicy";
import ProtectedRoute from "./utils/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerDetails } from "./redux/Auth/AuthSlice";
import Cookies from "js-cookie";
import ReservationPolicy from "./components/ReservationPolicy";

const App = () => {
  const [loading, setLoading] = useState(true);
  const { customerDetails, customerStatus, customerError } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = Cookies.get("userId");
    if (userId) {
      dispatch(fetchCustomerDetails(userId));
    }
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation(); // Now inside a component that is within <BrowserRouter>

  return (
    <>
     
      {location.pathname !== "/" && <Navbar />}

      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/authenticate" element={<Authenticate />} />
    
          <Route path="/home" element={<ProtectedRoute element={Home} />} />
          <Route path="/destinations" element={<ProtectedRoute element={Destinations} />} />
          
          <Route path="/events" element={<ProtectedRoute element={Events} />} />
          <Route path="/eventdetail" element={<ProtectedRoute element={EventDetail} />} />
          <Route path="/eventrooms" element={<ProtectedRoute element={EventRooms} />} />
          <Route path="/daypass" element={<ProtectedRoute element={Daypass} />} />
          <Route path="/blogs" element={<ProtectedRoute element={Blogs} />} />
          <Route path="/blogs/blogdetail/:blogId" element={<ProtectedRoute element={Blog_detail} />} />
          <Route path="/contact" element={<ProtectedRoute element={Contact} />} />
          <Route path="/home/bookings/:propertyId" element={<ProtectedRoute element={Bookings} />} />
          <Route path="/mybookings" element={<ProtectedRoute element={MyBooking} />} />
          <Route path="/cancel" element={<ProtectedRoute element={Cancel} />} />
          <Route path="/proceedcancel" element={<ProtectedRoute element={ProceedCancel} />} />
          <Route path="/refundscreen" element={<ProtectedRoute element={RefundScreen} />} />
          <Route path="/home/properties" element={<ProtectedRoute element={Properties} />} />
          <Route path="/destinations/propertiedetail/:location" element={<ProtectedRoute element={PropertiesDetail} />} />
          <Route path="/home/productdetail/:propertyName/:propertyCity/:propertyId/:roomId/:booked" element={<ProtectedRoute element={Product_detail} />} />
          <Route path="/home/orderdetail" element={<ProtectedRoute element={Order_detail} />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/cancellationpolicy" element={<CancelPolicy />} />
          <Route path="/reservationpolicy" element={<ReservationPolicy />} />
          <Route path="/myprofile" element={<ProtectedRoute element={MyProfile} />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>
       
      </div>

      {location.pathname !== "/" && <Footer />}
    </>
  );
};

export default App;
